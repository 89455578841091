import React from "react";
import SocialMediaLinks from "./components/SocialMediaLinks";
import SvgBackground from "./components/SvgBackground";
import "./Frontpage.css";
import TypeWriterEffect from "react-typewriter-effect";

function Frontpage() {
  return (
    <>
    
      <div className="profile-container" id="home_page">
        <div className="profile-parent">
          <div className="profile-details">
            <div className="profile-details-name">
              <p className="primary-text">
                Hey, I'm
                <span className="highlighted-text">João Soares</span>
              </p>
            </div>
            <div className="profile-details-role">
              <span className="primary-text">
                <h1 style={{ display: "flex", gap: "6px" }}>
                  
                  <span style={{ color: "#7000f0" }}>
                    <TypeWriterEffect
                      multiTextLoop
                      cursorColor=" #7000f0 "
                      multiText={[
                        "UX/UI Designer",
                        "UX Researcher",
                        "Branding Designer",
                        "Data management ",
                        "Prototyping/Wireframes Webpages and Mobile apps",
                      ]}
                      multiTextDelay={0}
                      typeSpeed={0}
                    />
                  </span>
                </h1>
                <span className="profile-role-tagline">
                Welcome to my innovative and creative online haven! My name is João Soares, and I'm a passionate, ambitious person who has a strong love for design and a strong ambition to change the world. 
                The main objective of this website is to share the work that has influenced my career as a UX designer.
                </span>
              </span>
              <div className="colz">
                <SocialMediaLinks />
              </div>
            </div>

            <div className="profile-options">
              <a href="#contact_page">
               {/* <button className="btn primary-btn">Get in touch</button> */}
              </a>
             {/*  <a href="./notavailable.txt" download> */}
                {/* <button className="btn highlighted-btn">Resume</button> */}
              {/* </a> */}
            </div>
          </div>
          <div className="profile-picture">
            <div className="profile-picture-background"></div>
          </div>
        </div>
        <SvgBackground />
      </div>
    </>
  );
}

export default Frontpage;
