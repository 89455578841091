import React from "react";
import "./Footer.css";
function Footer() {
  return (
    <div>
      <div className="footer_section">
        <div className="line"></div>
        <h2>João Soares Portfolio website  |  Mobile first approach ✅ </h2>
      </div>
    </div>
  );
}

export default Footer;
