const social_media_data = [
  {
    index: 1,
    icon_class: "fas fa fa-instagram",
    where_to_go_link: "https://www.instagram.com/spizeit",
  },
  {
    index: 2,
    icon_class: "fas fa fa-linkedin",
    where_to_go_link: "https://www.linkedin.com/in/joao-pereira-soares/",
  },
  {
    index: 3,
    icon_class: "fas fa fa-twitter",
    where_to_go_link: "https://twitter.com/spizeit",
  },
];

export default social_media_data;
