import React from "react";
import "./Contact.css";
import "react-toastify/dist/ReactToastify.css";

const Contact = () => {
  return (
    <section className="contact_page_section" id="contact_page">
      <div className="contact_section">
        <div className="contact_container">
          <div className="form_container_left">
            </div>
          </div>

      </div>
    </section>
  );
};

export default Contact;
