import Logo from '../../Image/proj_imgs/Logo.png';
import gutenberg from '../../Image/proj_imgs/project_gutenberg.png';
import mockup from '../../Image/proj_imgs/mock-up.png';
import weightloss from '../../Image/proj_imgs/weight-loss.png';
import casestudy from '../../Image/proj_imgs/casestudy.png';
import components from '../../Image/proj_imgs/component.png';
import presentation from '../../Image/proj_imgs/presentation.png';
import wireframes from '../../Image/proj_imgs/wireframe.png';
import userjourney from '../../Image/proj_imgs/userjourney.png';

const proj_data = [
  {
    id: 1,
    project_name: 'Logo Branding "Odyssey"',
    proj_creation_date: 'January 2021',
    project_image: Logo,
    proj_code_like: 'https://www.behance.net/gallery/107872807/Odyssey-Digital-Design',
    proj_live_link: 'https://www.behance.net/gallery/107872807/Odyssey-Digital-Design',
  },
  {
    id: 2,
    project_name: 'User Journey Video Explanation',
    proj_creation_date: 'January 2023',
    project_image: userjourney,
    proj_code_like: 'https://www.youtube.com/watch?v=Vn_8abyVRe4',
    proj_live_link: 'https://www.youtube.com/watch?v=Vn_8abyVRe4',
  },
  {
    id: 3,
    project_name: 'Video Presentation of Design Problem',
    proj_creation_date: 'November 2021',
    project_image: presentation,
    proj_code_like: 'https://www.youtube.com/watch?v=fkVq0fzm9Ws',
    proj_live_link: 'https://www.youtube.com/watch?v=fkVq0fzm9Ws',
  },
  {
    id: 4,
    project_name: 'Wireframes Gutenberg Figma',
    proj_creation_date: 'May 2022',
    project_image: wireframes,
    proj_code_like: 'https://www.figma.com/file/XRM9UIrzKrDaeraIR0QqaK/Project-Gutenberg-Wireframes-%2B-Final-design?type=design&t=p4IxWB7zAtrncCQ0-1',
    proj_live_link: 'https://www.figma.com/file/XRM9UIrzKrDaeraIR0QqaK/Project-Gutenberg-Wireframes-%2B-Final-design?type=design&t=p4IxWB7zAtrncCQ0-1',
  },
  {
    id: 5,
    project_name: 'Redesign Project Gutenberg',
    proj_creation_date: 'May 2022',
    project_image: gutenberg,
    proj_code_like: 'https://www.behance.net/gallery/111279173/Project-Gutenberg-Redesign',
    proj_live_link: 'https://www.behance.net/gallery/111279173/Project-Gutenberg-Redesign',
  },
  {
    id: 6,
    project_name: 'Case Study Nintendo Switch (Notion)',
    proj_creation_date: 'January 2022',
    project_image: casestudy,
    proj_code_like: 'https://casestudyae2joaopereirasoares.notion.site/Case-Study-Nintendo-Switch-Online-System-8f2a1aa4baba4e3db8e743d6001677a1',
    proj_live_link: 'https://casestudyae2joaopereirasoares.notion.site/Case-Study-Nintendo-Switch-Online-System-8f2a1aa4baba4e3db8e743d6001677a1',
  },
  {
    id: 7,
    project_name: '5 Interactive Components',
    proj_creation_date: 'November 2022',
    project_image: components,
    proj_code_like: 'https://medium.com/@4perej88/interaction-design-5-components-642f84079b2b#d0ce-33124c5db7d8',
    proj_live_link: 'https://medium.com/@4perej88/interaction-design-5-components-642f84079b2b#d0ce-33124c5db7d8',
  },
  {
    id: 8,
    project_name: 'Mock-up Single Page App (Figma only)',
    proj_creation_date: 'Nov 2022',
    project_image: mockup,
    proj_code_like: 'https://www.figma.com/file/19EQVt8OxYLbwyN3Oaz4fb/Untitled?type=design&t=QELwWAhPG96o32TB-1',
    proj_live_link: 'https://www.figma.com/file/19EQVt8OxYLbwyN3Oaz4fb/Untitled?type=design&t=QELwWAhPG96o32TB-1',
  },
  {
    id: 9,
    project_name: 'Personal Weight Loss Tracker',
    proj_creation_date: 'Initial Stage ⚠️',
    project_image: weightloss,
    proj_code_like: 'https://weight-loss-peach.vercel.app/',
    proj_live_link: 'https://weight-loss-peach.vercel.app/',
  },

];

export default proj_data;
