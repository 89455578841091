import React from "react";
import proj_img from "../../Image/technology.gif";
import "./ProjHome.css";
function ProjHome() {
  return (
    <>
      <div className="proj_containar_section">
        <div className="proj_left">
          <h3>Projects & Experiences</h3>
          <p>
          During my three years at university, I had the incredible opportunity to immerse myself in a wide range of  projects, adjusting my skills and exploring my creativity. 
          These experiences not only challenged me as a student but also shaped my perspective on the power of design to influence and inspire others.
          As I embark on the next chapter of my career, I carry with me the invaluable lessons learned and the confidence to tackle new challenges. I am excited to continue growing as a designer, pushing boundaries, and making a positive impact through design.
          </p>
          <h1 class="animate-character">Check my recent projects below!</h1> 
        </div>
        <div className="proj_right">
          <img src={proj_img} alt="Virtual Reality" />
        </div>

      </div>
    </>
  );
}

export default ProjHome;
