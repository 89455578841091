import React from "react";
import about_me_img from "../Image/meeting.gif";
import personal_info_data from "./assets/AboutIn";
import "./About.css";
function About() {
  return (
    <section className="about_me" id="about_me">
      <div className="container_01">
        <div className="my_img">
          <img id="img" src={about_me_img} alt="profile_image" />
        </div>
        <div className="my_info">
        <h1 class="playful" aria-label="Wash your hands">
          <span aria-hidden="true">A</span><span aria-hidden="true">B</span><span aria-hidden="true">O</span>
          <span aria-hidden="true">U</span><span aria-hidden="true">T </span><span aria-hidden="true"></span>
          <span aria-hidden="true">M</span><span aria-hidden="true">E</span><span aria-hidden="true"></span>
          </h1>
          <p>
          I'm an enthusiastic designer with a sharp eye for aesthetics and a strong desire to create memorable user experiences. 
          If you're seeking a dedicated and adaptable UX and UI designer who is passionate about creating engaging visual experiences, I'd love to connect and talk about how we can collaborate to make your ideas a reality.
          Please contact me, and let's discuss what I can do for you.
          </p>
          <div className="personal_info">
            {personal_info_data.map((data, index) => {
              return (
                <>
                  <div className="abc" key={index}>
                    {data.section_one.map((e, index) => {
                      return (
                        <h4 key={index}>
                          <span>{e.data_type} :</span> {e.data_value}
                        </h4>
                      );
                    })}
                  </div>
                  
                </>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
