import react_logo from "../../Image/logo_folder/resct_logo.png";
import node_logo from "../../Image/logo_folder/node-js.png";
import html_logo from "../../Image/logo_folder/html_logo.png";
import css_logo from "../../Image/logo_folder/css_logo.png";
import mysql_logo from "../../Image/logo_folder/mysql_logo.png";
import python from "../../Image/logo_folder/python.png";
import java_logo from "../../Image/logo_folder/java_logo.png";
import javascript_logo from "../../Image/logo_folder/js_logo.png";
import github_logo from "../../Image/logo_folder/github_logo.png";
import illustrator from "../../Image/logo_folder/illustrator.png";
import behance from "../../Image/logo_folder/behance.png";
import material_logo from "../../Image/logo_folder/material_logo.png";
import express_logo from "../../Image/logo_folder/express_logo.png";
import vercel from "../../Image/logo_folder/vercel.svg";
import photoshop from "../../Image/logo_folder/photoshop.png";
import figma from "../../Image/logo_folder/figma.png";


const my_skills_data = [
  {
    id: 1,
    skill_name: "React JS",
    img_link: react_logo,
  },
  {
    id: 2,
    skill_name: "Figma",
    img_link: figma,
  },

  {
    id: 3,
    skill_name: "JavaScript",
    img_link: javascript_logo,
  },
  {
    id: 4,
    skill_name: "Node JS",
    img_link: node_logo,
  },
  {
    id: 5,
    skill_name: "Express JS",
    img_link: express_logo,
  },
  {
    id: 6,
    skill_name: "HTML5",
    img_link: html_logo,
  },
  {
    id: 7,
    skill_name: "CSS3",
    img_link: css_logo,
  },
  {
    id: 8,
    skill_name: "Photoshop",
    img_link: photoshop,
  },
  {
    id: 9,
    skill_name: "Python",
    img_link: python,
  },
  {
    id: 10,
    skill_name: "Material UI",
    img_link: material_logo,
  },
  {
    id: 11,
    skill_name: "MySQL",
    img_link: mysql_logo,
  },
  {
    id: 12,
    skill_name: "Illustrator",
    img_link: illustrator,
  },
  {
    id: 13,
    skill_name: "Java",
    img_link: java_logo,
  },
  {
    id: 14,
    skill_name: "GitHub",
    img_link: github_logo,
  },
  {
    id: 15,
    skill_name: "Behance",
    img_link: behance,
  },
  {
    id: 16,
    skill_name: "Vercel",
    img_link: vercel,
  },
];
export default my_skills_data;
