import React from "react";
import SData from "./assets/ApplicationData";
import "./Skills.css";
function Skills() {
  return (
    <>
      <section id="skills">
        <div className="my_skills">
             <h1 class="animate-charcter">tools that I'm good at</h1> 

        </div>
        <div className="section_container_skills_gif">
          <div className="skills_gif_container">
           
          </div>
        </div>
        <div className="container_s">
          {SData.map((e, index) => (
            <div
              className="skill_box"
              key={index}
              style={{ boxShadow: `0px 0px 12px ${e.bx_shadow}` }}
              data-aos="fade-right"
              data-aos-duration="2000"
            >
              <div className="content_img_container">
                <img
                  src={e.img_link}
                  alt={e.img_content}
                  style={{ height: e.img_height, filter: e.img_styles }}
                />
              </div>
              <h3>{e.skill_name}</h3>
            </div>
          ))}
        </div>
      </section>
    </>
  );
}

export default Skills;
