const personal_info_data = [
  {
    index: 1,
    section_one: [
      {
        index: 1,
        data_type: "Age",
        data_value: "27 years old",
      },
      {
        index: 2,
        data_type: "I speak fluently",
        data_value: "English & Portuguese",
      },
      {
        index: 3,
        data_type: "Email",
        data_value: "joaosoaresparticular@gmail.com",
        icon_class: "fa-solid fa-envelope",
        ic_color: "#00838F",
      },     
      {
        index: 4,
        data_type: "Native Country",
        data_value: "Portugal",
        icon_class: "fa-solid fa-envelope",
        ic_color: "#00838F",
      },   
    ],
  },
];

export default personal_info_data;
